import React from "react"

import "./KChHeader.scss"

export default function kchHeader({ data }) {
  const { images, title, subtitle } = data
  return (
    <section
      className="kch-header"
      style={{ backgroundImage: `url(${images.bgHeader})` }}
    >
      <div className="kch-header-wrapper">
        <div className="kch-header-inner-wrapper">
          <div className="kch-header-textBlock">
            <h1 className="kch-header-title">{title}</h1>
            <p className="kch-header-subtitle">{subtitle}</p>
          </div>
          <div className="kch-header-logoContainer">
            <img
              src={images.header}
              alt="Carpatian Seagull application"
              className="kch-header-logo"
            />
          </div>
        </div>
      </div>
    </section>
  )
}
