import React from "react"

import OverviewComponent from "../OverviewComponent"

export default function KarpatskaChaika({ data }) {
  let i = 0
  return [
    // <section key={i++}>
    //   <div className='bgBlock bgImage Chaika' style={{backgroundImage: `url(${data.images.bgHeader})`}}>
    //     <div className="container containerImgChaika">
    //       <div className='row'>
    //         <div className='col-xs-12'>
    //           <div className='titleBlock'>
    //             <h1>{data.title}</h1>
    //             <span>{data.subtitle}</span>
    //           </div>
    //           <img src={data.images.header} alt='Carpatian Seagull application' />
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </section>,

    <OverviewComponent {...data} classComponent="noMarginTop" key={i++} />,

    <section className="Chaika image solution" key={i++}>
      <div className="container containerImgChaika">
        <img src={data.images.section3} alt="Carpatian Seagull application" />
      </div>
    </section>,

    <section className="Chaika compose" key={i++}>
      <section className="solution first">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <h2>{data.solution.title}</h2>
              {data.solution.textFirst.map((item, i) => (
                <p className="mb-4" key={i}>{item}</p>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="Chaika image image solution" key={i++}>
        <div className="container containerImgChaika">
          <img src={data.images.webcam} alt="Carpatian Seagull application" />
        </div>
      </section>
    </section>,

    <section className="solution last Chaika" key={i++}>
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            <h2 className="noMarginTop">{data.delivered.title}</h2>
            <ul>
              {data.delivered.list.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>,
  ]
}
