import {
  BgHeader,
  BgSolution,
  Header,
  Section3,
  Webcam,
} from "../../images/SingleCase/KarpatskaChaika"

const dataForKarpatskaChaika = {
  type: "project",
  smart: true,
  classComponent: "Chaika",
  nextLink: "cases/iot/",
  prevLink: "cases/wikigrads/",
  data: {
    title: "Carpathian Seagull",
    subtitle: "Flexible ski resort management system",
    overview: {
      title: "Project overview",
      about: {
        title: "About client",
        content:
          "Our client is owner of the Carpathian Seagull hotel and co-owner of Dragobrat ski-resort complex.",
      },
      challenge: {
        title: "Business challenge",
        content:
          "The main customers target was to improve overall payment and pricing system in their company, but the project has grown into full-scale operational process automatization and upgrade. A lot of manual efforts is applied just to change the pricing and on top of that - no possibility to do it separately for each terminal was the most critical topics for customer. After closer look we discovered that there are additional process gaps exists - poor reporting systems, low security, old hardware and no automated feedback to clients.",
      },
      specific: [
        {
          type: "Platform",
          content: ["Web", "Desktop"],
          icon: "fa-th-large",
        },
        {
          type: "Technology",
          content: [
            "Java",
            "Spring",
            "Hibernate",
            "JPA",
            "Angular.JS",
            "Oracle",
            "Delphi",
            "XML",
            "HTML",
            "Docker",
          ],
          icon: "fa-cog",
        },
        {
          type: "Team",
          icon: "fa-users",
          content: [
            {
              type: "Designer",
              count: 1,
            },
            {
              type: "Developers",
              count: 3,
            },
            {
              type: "DevOps",
              count: 1,
            },
            {
              type: "Project Manager",
              count: 1,
            },
            {
              type: "QA Engineer",
              count: 1,
            },
          ],
        },
        {
          type: "Duration",
          icon: "fa-calendar",
          content: [
            {
              type: "months",
              count: 6,
            },
          ],
        },
        {
          type: "Client",
          content: ["Enterprise"],
          icon: "fa-user",
        },
      ],
    },
    solution: {
      title: "Solution",
      textFirst: [
        "Flexible access control to all timezones and monitoring system for ski resort that allows automatized selling of ski passes, collecting survey analytics, monitoring the use of ski lifts and cafe visits.",
        "The system provides flexible analytics through splitting resort into zones and time. The software allows issuance of personalized ski passes to keep record of bonuses, discounts and individual lifts history.",
        "A flexible pricing system allows assigning points not only to each lift but to cafes and hotel visits. The integrated IP camera constantly streams video from surveillance cameras and allows enjoying the scenery or checking weather conditions. Monitors were installed for the clients to check statuses of their ski passes.",
        "Our experts created system of admissions (ski-pass) based on a program complex of Supra one of which versions was used for the container terminal. Hardware upgrade (personal computers, internet connection systems, cameras, turnstile and others).",
        "Universality of system allowed to adapt it and to start a control and management system of access (SKUD) for ski-pass in work.",
      ],
    },
    delivered: {
      title: "Value delivered",
      list: [
        "Splitting resort into zones allows receiving flexible analytics",
        "Automation of work of throughput system on the elevator",
        "Detailed reporting by separate zones, lifts and ski passes",
        "Access rights management system",
        "Flexible pricing and discount system on ski lifts",
        "The dashboard shows residual amount of uses on each ski pass",
        "The issuance and sale of personalized ski passes",
        "Integration with cash registers and check printers",
        "Security improvement",
        "Overall company performance increased through upgraded hardware and software as well as process automation",
      ],
    },
    images: {
      bgHeader: BgHeader,
      header: Header,
      section3: Section3,
      bgSolution: BgSolution,
      webcam: Webcam,
    },
  },
  imageSrc: [],
  relatedProjects: [
    {
      link: "/cases/gud-job",
      id: "gud-job",
      title: "Gud Job",
    },
    {
      link: "/cases/cartovera",
      id: "cartovera",
      title: "Cartovera",
    },
    {
      link: "/cases/wikigrads",
      id: "wikigrads",
      title: "Wikigrads",
    },
    {
      link: "/cases/ilich-realty",
      id: "illich3",
      title: "Ilich Realty",
    },
  ],
  link: "/cases/karpatska-chaika",
}

export default dataForKarpatskaChaika
